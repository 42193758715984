<template>
	<div class="page">
		<div class="pageTitle">
			<div class="titleLeft" @click="$router.back()">
				<img class="iconBack" src="../../assets/img/back.png">
			</div>
			<div class="titleText">填写联系方式</div>
			<div class="titleRight"></div>
		</div>
		<img class="imgBg" src="../../assets/img/shopBg.png">
		<div class="msgBox">
			<div class="msgTit">联系方式</div>
			<div class="msgItem">
				<div class="label">您的姓名</div>
				<input class="value" type="text" v-model="name" placeholder="请输入您的姓名"/>
			</div>
			<div class="msgItem">
				<div class="label">联系电话</div>
				<input class="value" type="text" v-model="phone" placeholder="请输入手机号"/>
			</div>
			<div class="msgItem" style="height: 1.6rem;">
				<div class="label">居住地址</div>
				<textarea class="value" type="text" v-model="address" rows="3" placeholder="请输入住址" style="outline:none;resize:none;border:0;padding-top:.3rem;"/>
			</div>
		</div>
		<div style="height:1rem;"></div>
		<div class="submitBtn" @click="submit">提交</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				name:'',
				phone:'',
				address:'',
				
				petIntention:{},
			}
		},
		mounted(){
			this.petIntention = JSON.parse(sessionStorage.getItem('petIntention'))
		},
		methods:{
			submit(){
				if(this.$public.isNull(this.name)){
					this.$toast({
						message: '请输入姓名',
						icon: 'error',
					});
					return
				}
				if(!this.$public.isTel(this.phone)){
					this.$toast({
						message: '手机号输入有误',
						icon: 'error',
					});
					return
				}
				if(this.$public.isNull(this.address)){
					this.$toast({
						message: '请输入居住地址',
						icon: 'error',
					});
					return
				}
				let params = {
					"staffInfo": {
						"userId": this.petIntention.uId,
						"userName": this.petIntention.uName,
					},
					"intentionUserInfo": {
						"name": this.name,
						"mobile": this.phone,
						"address": this.address,
					},
					"applyType": this.petIntention.applyType,
					"epromNo": this.petIntention.epromNo,
					"categoryInfo":this.petIntention.categoryInfo,
					"breedInfo": this.petIntention.breedInfo,
					"petName": this.petIntention.petName,
					"petColor": this.petIntention.petColor,
					"petSex": this.petIntention.petSex,
				}
				this.$http.post(process.env.VUE_APP_Url_CRM + '/api/PetIntention/Insert', params).then(res => {
					if (res.code == 0) {
						this.$toast({
							message: '提交成功，稍后会有服务人员联系您',
							icon: 'success',
						});
						this.$router.back();
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
.page{
	width:100%;height: 100%;box-sizing: border-box;padding-top:.92rem;
	.pageTitle{
		display: flex;position: fixed;height: .92rem;background-color: #fff;top:0;z-index: 20;
		.titleLeft{
			width:1rem;
			.iconBack{
				height: .4rem;padding-top:.25rem;
			}
		}
		.titleText{
			width:5.5rem;font-size: .32rem;color:#333;font-weight: bold;padding-top:.22rem;
		}
		.titleRight{
			width:1rem;padding-top:.22rem;
		}
	}
	.imgBg{
		width:100%;height: 4.46rem;
	}
	.msgBox{
		width:7rem;height:4.9rem;background-color: #fff;border-radius: .16rem;padding:.32rem;box-sizing: border-box;border:.02rem solid #f0f0f2;margin:-0.9rem auto 0;position: relative;
		.msgTit{
			display: flex;font-size: .32rem;color:#1a1a1a;
		}
		.msgItem{
			display: flex;font-size: .28rem;border-bottom: .02rem solid #f0f0f2;height: 1rem;
			.label{
				color:#333;width:1.5rem;line-height: 1rem;margin-right: .2rem;
			}
			.value{
				color:#333;width:4.2rem;
			}
		}
	}
	.submitBtn{
		width:6.86rem;line-height: .92rem;background-color: #1b8dff;border-radius: .06rem;color:#fff;font-size: .28rem;position: relative;left:.3rem;
	}
}
</style>
